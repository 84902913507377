.Media {
    margin: 0 2em 0 2em;
}

.Media-content {
    display: flex;
    flex-direction: row;
    padding: 0 4px;
    flex-wrap: wrap;
}

.Media-column {
    flex: 33;
    max-width: 33%;
    padding: 0 4px;
}
.Media-column > div > iframe{
    margin: 0 auto 12px auto !important;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 800px) {
    .Media-column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media (max-width: 600px) {
    .Media-column {
      flex: 100%;
      max-width: 100%;
    }
  }