@media {
    .MainNav-list {
        padding-top: 2em;
        padding-bottom: 2em;

    }

    .MainNav-list .pure-menu-link {
        color: white;
    }

    .MainNav-list .pure-menu-link:hover {
        background-color: rgba(255, 0, 255, 255);
    }
}

@media (min-width: 48em) {
    .MainNav-list {
        position: absolute;
        bottom: 10px;
    }
}