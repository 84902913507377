.Footer {
    padding-top: 2em;
    padding-bottom: 4em;
    background-color: black;
}

.Footer .pure-menu-link img {
    width: 35px;
    height: 35px;
    filter: invert(19%) sepia(100%) saturate(2268%) hue-rotate(286deg) brightness(126%) contrast(147%);
}

@media (min-width: 48em) {
    /* md: medium devices */
    .Footer .pure-menu-link img {
        width: 50px;
        height: 50px;
    }


}