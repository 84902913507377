@media {
    body {
        margin: 0;
        font-family: 'Share Tech Mono', monospace;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #282c34;
        color: #ffffff;
        text-align: center;
    }

    h2 {
        text-align: center;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    a {
        color: rgba(255, 0, 255, 255);
    }
}


@media (min-width: 48em) {
    /* md: medium devices */
}