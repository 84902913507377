@media {
    /* small  */
    .Color-green {
        color: rgba(0, 255, 216, 255);
    }

    .Color-pink {
        color: rgba(255, 0, 255, 255);
    }

    .App {
        text-align: center;
        width: 100vw;
    }

    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        height: 40vmin;
        pointer-events: none;
    }

    .App-header {
        min-width: 100vw;
        /*min-height: 100vh;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: center;*/
        font-size: calc(10px + 1vmin);
        color: white;
        font-family: 'Share Tech Mono', monospace;
        background: url('/images/dj-choyce-hacks-loves-you.jpg');
        background-size: cover;
        background-color: #282c34;
        background-blend-mode: overlay;
        background-repeat: no-repeat;
    }

    .App-header h1 {
        font-family: 'Rajdhani', sans-serif;
        font-weight: bold;
        color: rgba(0, 255, 216, 255);
        font-size: 3em;
        margin-bottom: 0;
        padding-bottom: 0.2em;
    }

    .App-header .App-mediaPlayer {
        margin-top: 2.0em;
        width: 50vw;
    }

    .App-header .pure-menu {
        margin-top: 0;
    }

    .App-header .pure-menu-link img {
        width: 35px;
        height: 35px;
        filter: invert(19%) sepia(100%) saturate(2268%) hue-rotate(286deg) brightness(126%) contrast(147%);
    }

    .App-link {
        color: #61dafb;
    }


    .Body {
        padding: 0 1em 2em 1em;
        border-bottom: 1px solid #666666;
    }

    .spacer {
        height: 2em;
    }
}

@media (min-width: 48em) {
    /* md: medium devices */
    .App-header {
        min-height: 100vh;
        font-size: calc(10px + 2vmin);
        background-size: cover;
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .App-header .pure-menu-link img {
        font-size: 48px;
    }

    .Body {
        padding: 0 0 2em 0;
    }
}